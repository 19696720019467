<template>
  <div>
    <v-card class="mt-8">
      <div class="pa-4">
        <div class="font-14 text-center font-weight-bold">
          พิมพ์สายรัดข้อมือ
        </div>
        <div class="font-14 mt-4">
          ประจำวันที่:
          <span class="font-weight-bold font-14">{{
            formatDateThai(this.orders[0].schedule.date)
          }}</span>
        </div>
        <div class="font-14 mt-4">
          รอบ:
          <span class="font-weight-bold font-14">{{
            formatSchedule(
              this.orders[0].schedule.startTime,
              this.orders[0].schedule.endTime
            )
          }}</span>
        </div>
      </div>
    </v-card>
    <v-card class="my-8">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา (หมายเลขการจอง)"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-actions class="justify-end my-4">
        <v-btn v-print="printObj" color="green" dark class="font-weight-bold">
          <v-icon left>mdi-printer</v-icon>
          พิมพ์ {{ count }} รายการ
        </v-btn>
      </v-card-actions>
      <v-data-table
        :footer-props="footer"
        :headers="headers"
        :items="orders"
        :search="search"
        no-results-text="ไม่พบข้อมูลการค้นหา"
      >
        <template #loading> กำลังโหลดข้อมูล </template>
        <template #no-data> ไม่พบข้อมูล </template>
        <!-- <template v-slot:[`item.schedule`]="{ item }">
            {{ formatDate(item.schedule.date) }}
          </template> -->
        <template v-slot:[`item.orderId`]="{ item }">
          <a style="text-decoration: underline">{{ item.orderId }}</a>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type | bookingType }}
        </template>
        <template v-slot:[`item.branch`]="{ item }">
          {{ item.branch === "trang" ? "ตรัง" : "สาทร" }}
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ item.createdOn | formatMiniDate }}
        </template>
        <template v-slot:[`item.schedule.date`]="{ item }">
          {{ item.schedule.date | formatMiniDate }}
        </template>
        <template v-slot:[`item.time`]="{ item }">
          <div v-if="item.isGroup">
            {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
          </div>
          <div v-else>
            {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
          </div>
        </template>
        <template v-slot:[`item.atkStatus`]="{ item }">
          <span :class="item.atkStatus ? 'green--text' : 'red--text'">
            {{ item.atkStatus ? "ส่งแล้ว" : "ยังไม่ส่ง" }}
          </span>
        </template>
        <template v-slot:[`item.statusShow`]="{ item }">
          <div
            v-if="item.orderStatus === 'paid' && item.price.summary <= 0"
            :class="statusColor(item.orderStatus)"
          >
            คอนเฟิร์ม
          </div>
          <div v-else :class="statusColor(item.orderStatus)">
            {{ item.orderStatus | formatStatus }}
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- PRINT AREA -->
    <template>
      <div id="printArea">
        <div v-for="(order, index) in orders" :key="index">
          <div v-if="order.type === 'group'">
            <div
              style="padding-top: 27rem"
              v-for="i in order.joinQty"
              :key="i"
              class="print"
            >
              <div v-if="order.type === 'group'" class="rotated">
                <p>{{ wrapWord(order.organizationName) }}</p>
                <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
              </div>
              <div
                v-if="order.type !== 'group' && order.branch !== 'sathorn'"
                class="rotated"
              >
                <div
                  v-for="(participant, indexMember) in order[index].participant"
                  :key="indexMember"
                >
                  <p>{{ participant.firstname }}</p>
                  <p>{{ formatAge(participant.birthday) }} Y.</p>
                </div>
              </div>
              <div>
                <img
                  style="width 6rem; height: 6rem ; margin-top: 4rem;"
                  :src="order.qrcode"
                />
              </div>
              <div style="margin-top: 5rem" class="rotated">
                <span class="text-center">
                  {{ formatDate(order.schedule.date) }}
                </span>
                <div>
                  {{ order.schedule.startTime }} -
                  {{ order.schedule.endTime }}
                </div>
              </div>
              <div class="rotated">
                <img
                  style="width 5rem; height: 5rem ; margin-left: 4rem;"
                  src="/svg/PlanToys_Logo-01.svg"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div
              style="padding-top: 27rem"
              v-for="(item, index) in order.participant"
              :key="index"
              class="print"
            >
              <div v-if="order.type === 'group'" class="rotated">
                <p>{{ item.organizationName }}</p>
                <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
              </div>
              <div
                v-if="order.type !== 'group' && order.branch !== 'sathorn'"
                class="rotated"
              >
                <p>{{ item.firstname }}</p>
                <p>{{ formatAge(item.birthday) }} Y.</p>
              </div>
              <div>
                <img
                  style="width 6rem; height: 6rem ; margin-top: 4rem;"
                  :src="order.qrcode"
                />
              </div>
              <div style="margin-top: 5rem" class="rotated">
                <span class="text-center">
                  {{ formatDate(order.schedule.date) }}
                </span>
                <div>
                  {{ order.schedule.startTime }} -
                  {{ order.schedule.endTime }}
                </div>
              </div>
              <div class="rotated">
                <img
                  style="width 5rem; height: 5rem ; margin-left: 4rem;"
                  src="/svg/PlanToys_Logo-01.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import dayjs from "dayjs";
import axios from "../../../utils/axios.js";
export default {
  computed: {
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
  },
  data() {
    return {
      orders: [],
      search: "",
      count: 0,
      printObj: {
        id: "printArea",
      },
      headers: [
        {
          text: "หมายเลขการจอง",
          value: "orderId",
          sortable: false,
          width: "10rem",
        },
        {
          text: "สาขา",
          value: "branch",
          sortable: false,
        },
        {
          text: "ประเภทการจอง",
          value: "type",
          sortable: false,
          width: "10rem",
          align: "center",
        },
        {
          text: "วันที่ทำรายการ",
          value: "createdOn",
          sortable: false,
          width: "8rem",
        },
        {
          text: "วันที่จอง",
          value: "schedule.date",
          sortable: false,
          width: "8rem",
        },
        {
          text: "เวลา",
          value: "time",
          width: "9rem",
          sortable: false,
        },
        {
          text: "ชื่อ",
          value: "member.firstname",
          sortable: false,
          width: "8rem",
        },
        {
          text: "นามสกุล",
          value: "member.lastname",
          sortable: false,
          width: "8rem",
        },
        {
          text: "หมายเลขโทรศัพท์",
          value: "member.tel",
          align: "center",
          sortable: false,
          width: "9rem",
        },
        {
          text: "จำนวนผู้เข้าร่วม",
          value: "joinQty",
          align: "center",
          width: "7rem",
          sortable: false,
        },
        {
          text: "ผลตรวจ ATK / หนังสือขอเข้าเยี่ยมชม",
          value: "atkStatus",
          align: "center",
          width: "15rem",
          sortable: false,
        },
        {
          text: "สถานะ",
          value: "statusShow",
          align: "center",
          width: "8rem",
          sortable: false,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getOrderBySchedule();
  },
  methods: {
    async getOrderBySchedule() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `/order/schedule/${this.$route.params.slug}?orderStatus=paid`
        );
        let count = 0;
        let countGroup = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i].type === "group") {
            countGroup = countGroup + parseInt(data[i].joinQty);
          }
          if (data[i].type !== "group") {
            count = count + parseInt(data[i].participant.length);
          }
        }
        this.orders = data;
        this.count = count + countGroup;
      } catch (error) {
        console.error(error);
      }
    },
    wrapWord(val) {
      if (val.length > 15) {
        return `${val.slice(0, 15)}...`;
      }
      return val;
    },
    formatAge(val) {
      return dayjs().diff(val, "year");
    },
    formatDate(val) {
      return dayjs(val).locale("en").format("DD.MMM.YY");
    },
    formatDateThai(val) {
      return dayjs(val).locale("th").format("DD MMMM BBBB");
    },
  },
};
</script>
<style>
#printArea {
  display: none;
}

.rotated {
  transform: rotate(90deg);
}
.print {
  height: 28cm;
  /* height: 14cm; */
}
@media print {
  .print {
    height: 100vh;
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    overflow: hidden;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  #printArea {
    display: block;
  }
  .rotated {
    transform: rotate(90deg);
  }
  footer {
    page-break-after: always;
  }
}

.font-14 {
  font-size: 18px !important;
}
.bt {
  margin-top: 10rem;
  z-index: 99999;
}
</style>
